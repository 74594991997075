// @import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
@import "veriables.scss";
/*body {
  font-family: "Lato", sans-serif !important;
  font-size: 14px !important;
  background-color: #afafaf;
  overflow-y: scroll;
}
.btn {
  border-radius: 5px !important;
}

.btn.btn-primary {
  border-color: #1670da;
  background-color: #1670da;
}

.btn-primary:hover {
  color: #fff;
  border-color: #1670da;
  background-color: #1670da;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  border-color: #1670da;
  background-color: #1670da;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn.btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.app-container {
  background-color: #fff;
  padding: 0;
}



*/

.btn {
  &.disabled {
    opacity: 1;
  }
}
.sidebar-col {
  background-color: var(--content-bg, $content-bg);
}
html .form-control::-webkit-input-placeholder {
  opacity: 0.5;
}
html .form-control:-moz-placeholder {
  opacity: 0.5;
}
html .form-control::-moz-placeholder {
  opacity: 0.5;
}
html .form-control:-ms-input-placeholder {
  opacity: 0.5;
}

[class*="-placeholder"] {
  /* WE USE * HERE */
  opacity: 0.5;
}

input[type="date"]:invalid::-webkit-datetime-edit {
  color: #ff0000 !important;
}
input[type="date"] {
  opacity: 0.5 !important;
}
input[type="date"]:not(.has-value) {
  opacity: 1 !important;
}
.required-notation {
  color: var(--pink, $pink);
}
.was-validated .form-control:valid,
.form-control.is-valid,
.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none;
}
.lead-details {
  list-style: none;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 0.9rem;
  li {
    font-size: 0.8rem;
    &:not(:last-child) {
      padding-bottom: 1rem;
    }
    &:first-child {
      padding-top: 1rem;
    }
  }
}
.documents-list {
  font-size: 0.9rem;
}
ul.course-list {
  padding: 0 1rem !important;
  li {
    padding: 0 !important;
    margin: 0 !important;
  }
}
