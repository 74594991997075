@import "./veriables.scss";
/*.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}*/

.route-container {
  flex: auto;
  flex-wrap: nowrap !important;
  background-color: var(--content-bg,#fcfcfc);
  .right-content {
    background-color: var(--content-bg, $content-bg);
    overflow-y: scroll !important;
    overflow-x: hidden;
    padding-top: 1rem;
    min-width: calc(100% - 244px);
    transition: all 0.25s ease;
    padding-left: 24px;
    padding-right: 24px;
    max-width: 100%;  
    @media(max-width:480px){
      margin-top: 3.5rem;
    }
    .footer-row {
      margin-top: auto;
    }
  }

  .left-content {
    z-index: 10;
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    background: #fff !important;
    max-width: 244px !important;
    min-width: 244px !important;
    transition: width 0.25s ease, 0.25s ease;
    -webkit-transition: width 0.25s ease, 0.25s ease;
  }
}
.loader {
  display: none;
}
.showLoader {
  .loader {
    z-index: 100;
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.9);
    left: 0;
    display: flex;
    justify-content: center;
    top: 0;
    align-items: center;
    .animation {
      top: calc(50% - 4em);
      left: calc(50% - 4em);
      width: 6em;
      height: 6em;
      border: 1.1em solid rgba(255, 255, 255, 0.8);
      border-left: 1.1em solid var(--defaultLoaderPrimary);
      border-radius: 50%;
      animation: load8 1.1s infinite linear;
      @media (max-width:480px){
        width: 4em;
        height: 4em;
        border: 0.7em solid rgba(255, 255, 255, 0.8);
        border-left: 0.7em solid var(--defaultLoaderPrimary);
      }
    }
  }
}
.api-loader {
  .route-loader {
    display: none;
  }
}
.route-loader {
  display: flex;
  flex: auto;
  justify-content: center;
  top: 0;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100% !important;
  z-index: 100;
  background: rgba(0, 0, 0, 0.9);
  left: 0;
  top: 0;
  .animation {
    top: calc(50% - 4em);
    left: calc(50% - 4em);
    width: 6em;
    height: 6em;
    border: 1.1em solid rgba(255, 255, 255, 0.8);
    border-left: 1.1em solid var(--defaultLoaderPrimary);
    border-radius: 50%;
    animation: load8 1.1s infinite linear;
    @media (max-width:480px){
      width: 4em;
      height: 4em;
      border: 0.7em solid rgba(255, 255, 255, 0.8);
      border-left: 0.7em solid var(--defaultLoaderPrimary);
    }
  }
}
@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.generic-modal {
  border-radius: 0.6rem;
  .modal-content {
    background-clip: inherit;
    border-radius: 0.5rem;
    .modal-header {
      align-items: center;
      background: var(--secondary);
      height: 3.75rem;
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
      padding-left: 1.5rem;
      border-bottom: none;
      .close {
        span {
          font-size: 1.5rem !important;
        }
      }
      .modal-title {
        font-weight: 600;
        font-size: 1.125rem;
      }
      .close {
        font-weight: 600;
        span {
          padding-right: 1rem;
          font-size: 2rem;
        }
        &:focus {
          outline: none;
        }
      }
    }
    .modal-footer {
      #submitModalBtn {
        min-width: 100px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0.5rem;
        border-radius: 6px;
        border: none;
      }
      #cancelModalBtn {
        background-color: white;
        border: 1px solid #c4c4c4;
        border-radius: 6px;
        min-width: 100px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 0.5rem;
        color: #393939;
        &:hover {
          background-color: var(--secondary) !important;
          border-radius: 6px;
        }
      }
    }
  }
}

@media (max-width: 1299px) {
  .container-fluid {
    margin: 0 !important;
    padding: 0 !important;
    background: #444;
  }
  .route-container {
    width: 100%;
    .overlay {
      position: absolute;
      width: 100%;
      height: calc(100vh - 4rem);
      z-index: 1000;
      opacity: 0.64;
    }
    .left-content {
      display: none;
    }
    .right-content {
      min-width: 100% !important;
    }
  }
  .small-screen-sidebar-effect {
    max-height: calc(100vh);
    opacity: 0.64;
  }
  #__eedivChatMain #__eechatWindow {
    left: 5rem;
    bottom: 5rem !important;
  }
}

.instructions {
  cursor: pointer;
  span {
    color: #393939;
    font-size: 14px;
    font-weight: 600;
    margin-right: 4px;
  }
}

.border-box-with-shadow {
  background: #fdfeff;
  box-shadow: 0 0.1rem 1rem 0 rgba(0, 0, 0, 0.03);
  border-radius: 0.5rem;
  border: 1px solid #d8d8d8;
}

.mb-24 {
  margin-bottom: 24px;
}

.no-negative-margins {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.no-padding-margin-container {
  padding-right: 0 !important;
  padding-left: 0 !important;
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.no-negative-padding {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.disabled-cursor {
  cursor: not-allowed !important;
}

#__eedivChatMain #__eechatWindow {
  left: 262px;
  bottom: 6% !important;
}
#__eechatIcon {
  display: none !important;
}

.public-routes-scroll {
  max-height: calc(100vh - 3.5rem) !important;
  overflow-y: auto !important;
  overflow-x: hidden;
}

button:focus {
  outline: none !important;
}

.block-screen {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

#loading-spinner {
  width: 22px;
  height: 22px;
  border: 3px solid white;
  border-radius: 50%;
  border-top-color: var(--primary);
  animation: spin 1s linear infinite;
  -webkit-animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@media (max-width: 747px) {
  #__eedivChatMain #__eechatWindow {
    left: 10rem;
    bottom: 0;
  }
}

@media (max-width: 547px) {
  #__eedivChatMain #__eechatWindow {
    left: 0;
    bottom: 0 !important;
  }
}
#cancelModalBtn {
  &:hover {
    background-color: var(--secondary);
  }
}

.moveInLeftAnimation {
  animation-name: moveInLeft;
  animation-duration: 0.4s;
}
@keyframes moveInLeft {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0%);
  }
}

.moveInLeftNegativeAnimation {
  animation-name: moveInLeftNegative !important;
  animation-duration: 0.4s;
}
@keyframes moveInLeftNegative {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translate(-100%);
  }
}

.moveInRightAnimation {
  animation-name: moveInRight;
  animation-duration: 0.4s;
}
@keyframes moveInRight {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translate(100%);
  }
}

.moveInRightNegativeAnimation {
  animation-name: moveInRightNegative;
  animation-duration: 0.4s;
}
@keyframes moveInRightNegative {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translate(0);
  }
}

.skeleton-loader {
  background: #EBEBEB;
  background: linear-gradient(110deg, #f4f4f4 8%, #eee 18%, #f4f4f4 33%);
  border-radius: 4px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

  .modal-backdrop{
    height: calc(100vh + 1rem) !important;
  }
  .modal-header {
    h5 {
      font-size: 1rem;
      font-weight: 600;
    }
  }
  .modal-content {
    .modal-body {
      .react-datepicker__input-container input[type=text] {
        width: 250px;
      }
      .react-datepicker-popper{
        transform: translate3d(19px, 74px, 0px) !important;
      }
      .react-datepicker-popper[data-placement^="top"] {
        margin-top: 10px;
      }
      overflow-y: auto;
      max-height: calc(100vh - 240px);

      &::-webkit-scrollbar-thumb {
        background-color: #c4c4c4;
        border-radius: 0.125rem;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar {
        width: 0.3rem;
      }

      &.no-scroll {
        scrollbar-width: none;
        scrollbar-color: transparent transparent;
      }

    }
  }
