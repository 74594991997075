@import '../../../veriables.scss';

.verification-modal {
    .ap-form-group.form-group {
      background-color: var(--input-field-bg, $input-field-bg);
      border-radius: 5px 5px 0 0;
      border-bottom: solid 1px
        var(--input-field-bottom-border-color, $input-field-bottom-border-color);
      margin-bottom: 1.75rem;
    }
    .ap-form-group input {
      background-color: transparent;
      border: transparent;
    }
    .ap-form-group > label {
      margin: 0.5rem 0 0 0.75rem;
      padding-top: 0.1rem;
      font-size: 0.9rem;
      font-weight: 700;
    }
    .form-control {
      background-color: transparent !important;
      border-color: transparent !important;
      box-shadow: none !important;
    }
    
    .ap-form-group .close-icon {
      height: 15px;
      width: 15px;
      margin-bottom: 4px;
      float: right;
    }
    
    .ap-form-group .close-icon:hover {
      cursor: pointer;
    }
  .form-group {
    .from-group-bg {
      background: #F2F1F1;
      padding: 5px 10px 5px 10px;
      color: black;
      border-radius: $formInputRadius;
      .form-control {
        border: none;
        border-radius: 0;
        padding: 0;
        &:focus {
          box-shadow: none;
        }
      }
      label {
        display: flex;
        margin: 0;
        font-size: 0.8rem;
        font-weight: 600;
      }
      input {
        background-color: transparent;
      }

      .react-tel-input {
        .form-control {
          margin-left: 50px;
        }
      }

      .flag-dropdown {
        border: none;
        background-color: #f2f1f1 !important;
        :hover{
            background-color: #f2f1f1 !important;
        }
        :focus{
            background-color: #f2f1f1 !important;
        }
      }
    }

      .invalid-feedback {
        display: block;
      }
  }

  .modal-style {
    height: 424px;
    max-width: 536px;
    border-radius: 8px;
    overflow: hidden;

    @media only screen and (max-width: 350px) {
      height: 450px;
    }
  }

  #modal-loader-container {
    height: 4px;
    background-color: white;
    width: 100%;
    /*border-top-right-radius: 8px;
    border-top-left-radius: 8px;*/
    overflow: hidden;
    visibility: hidden;
  }

  #modal-loader {
    background-color: $primary;
    width: 200px;
    height:inherit;
    margin-left: -300px;
    border-radius: 10px;
  }

  .modal-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top: 20px;
    left: 0;
    right: 0;
    border-radius: 50%;
    background-color: white;
    height: 88px;
    width: 88px;
  }

  .modal-head-main {
    height: 108px;
  }

  .modal-head-top {
    height: 60px;
    overflow: hidden;
    background-color: var(--secondary);
  }

  .modal-head-bottom {
    height: 48px;
  }

  .modal-body-form {
    h4 {
      margin-bottom: 20px;
      font-weight: 500;
      font-size: 24px;}
  }

  .modal-body-main {
    height: 75%;
  }

  .modal-body-body {
    font-size: 14px;
    margin-bottom: 16px;
  }

  .modal-form-input {
    mix-blend-mode: normal;
    border: 1px solid #D8D8D8;
    border-radius: 6px;
    height: 32px;
    width: 100%;
  }

  .button-primary {
    color: #ffffff;
    border: 1px solid transparent;
    background-color: $primary;
  }

  .button-primary:hover {
    border-color: $primary;
    background-color: #125EB7;
  }

  .footer-btn {
    position: absolute;
    bottom: 10px;
    right: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .modal-form-input-first {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2%;
  }

  .button-secondary {
    color: $primary;

  }

  .modal-close-btn {
    margin: 2.5%;
    position: absolute;
    right: 2%;
    top: 0.5%;
    cursor: pointer;
  }

  .otp-input-frame {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 12px;
  }

  .otp-input {
    text-align: center;
    height: 48px;
    width: 48px;
    border: 1px solid #D8D8D8;
    margin: auto 12px auto 12px;
  }

  .otp-input:focus {
    border: 2px solid #7D7D7D;
  }

  .otp-error {
    display: flex;
    text-align: center;
    justify-content: center;
  }

  .modal-step-transition {
    opacity: 0;
    margin-top: 25px;
    font-size: 21px;
    text-align: center;
    -webkit-transition: opacity 2s ease-in;
    -moz-transition: opacity 2s ease-in;
    -o-transition: opacity 2s ease-in;
    -ms-transition: opacity 2s ease-in;
    transition: opacity 2s ease-in;
  }

  .carousel-item {
    height:300px;
    padding: 0px 24px 0px 24px;

    @media only screen and (max-width: 350px) {
      height: 325px;
    }
  }

  .verified-title {
    padding-top: 15% !important;
    h4 {
      margin-bottom: 0px !important;
    }
  }

  .verified-description {
    padding-top: 0% !important;
  }
  
  .go-to-dashboard {
    color: $primary;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}