/* header css will come here */
@import "../../../veriables.scss";
.header {
  background: #fff !important;
  box-shadow: -1px -1px 5px 0px rgba(0, 0, 0, 0.25) !important;
  padding: 0.25rem 1rem !important;
  padding-left: 26px !important;
  z-index: 99 !important;
  @media(max-width:480px){
    position: fixed !important;
    z-index: 999 !important;
  }
  .right-side-header {
    align-items: center;
    .logged-in-info {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      margin-right: 0.5rem;
      .logged-in-by {
        font-size: 0.8rem;
        display: flex;
        justify-content: flex-end;
      }
      .counselor-name {
        font-size: 0.815rem;
        font-weight: 600;
        color: #393939;
      }
    }
    .communication-header-icon {
      margin-right: 1.625rem;
      img {
        cursor: pointer;
      }
    }
    .ask-quey-header-btn {
      color: #fdfeff;
      font-size: 14px;
      border-radius: 4px;
      background-color: var(--primary);
      min-width: 98px;
      padding: 8px 16px;
      text-align: center;
      cursor: pointer;
      width: fit-content;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 32px;
    }
  }
  .btnMenuToggle {
    color: white;
    &:hover {
      color: white;
    }
  }
  .avatar {
    object-fit: cover;
    border-radius: 50%;
    max-width: 2rem;
    max-height: 2rem;
    min-width: 2rem;
    min-height: 2rem;
  }
  .ham-burger-icon {
    margin-left: -0.125rem;
    margin-right: 0.75rem;
    cursor: pointer;
    display: none;
  }
}

.navbar {
  .navbar-brand {
    margin-right: 0px !important;
    max-width: 218px;
    min-width: 218px;
    padding: 0px;
  }
  .appform-name {
    font-size: 0.875rem;
    color: #393939 !important;
    font-weight: 600;
    margin-left: 1.5rem;
  }
}

.nav-link {
  padding: 0.5rem 0rem !important;
}

.appLogo {
  margin-right: 15px;
  max-height: 35px;
  // font-size: 0.875rem;
  // color: #393939;
  // white-space: break-spaces;
  // cursor: pointer;
  @media (max-width: 480px) {
    span {
      display: none;
    }
  }
}

.faq-link {
  padding: 0 !important;
  color: #212529 !important;
}
@media (min-width: 1300px) {
  .left-side-bar {
    display: none !important;
  }
}
@media (max-width: 1299px) {
  .header-background {
    background-color: #bcbbbb !important;
  }
  .sidebar {
    width: 30%;
    background-color: #fff;
    background: #fff;
    min-height: calc(100vh - 3.5rem);
    flex-wrap: nowrap !important;
    padding: 1rem 0 0.5rem 0;
    border-top: 1px solid #d8d8d8;
  }

  .appform-name {
    width: 100%;
  }
  .ham-burger-icon {
    display: block !important;
  }
  .logged-in-info {
    display: none !important;
  }
  .my-profile {
    .container {
      display: block !important;
      max-height: unset !important;
    }
  }
}
@media (max-width: 747px) {
  .navbar {
    .appform-name {
      display: none;
    }
  }
  .navbar-brand {
    font-size: 1rem !important;
    margin-right: 0 !important;
    min-width: fit-content !important;
    padding-left: 14px !important;
  }
}
@media (max-width:480px){
  .navbar-brand {
    span{
      display: none;
    }
  }
}
@media (max-width: 767px) {
  .header {
    justify-content: start !important;
  }
  .navbar-brand {
    font-size: 1rem !important;
    margin-right: 0px !important;
    margin-left: 1rem;
  }

  .navbar-toggler {
    padding: 0 0.25rem;
  }

  .faq-link {
    // color: #fff !important;
  }
  .sidebar {
    width: 60% !important;
  }
  .sidebar-header {
    width: 60% !important;
  }
}
.profile-modal-content {
  .modal-content {
    .modal-body {
      padding-left: 32px;
      padding-right: 32px;
      .profile-modal-body {
        .profile-photo {
          border-radius: 50%;
          width: 120px;
          height: 120px;
          object-fit: cover;
        }
        .form-group {
          margin-top: 16px;
          .select-label {
            font-size: 14px;
            color: #656565;
          }
          .custom-file {
            .custom-file-label {
              font-size: 12px;
            }
          }
          .description {
            font-size: 13px;
          }
        }
      }
    }
    .modal-footer {
      button {
        min-width: 98px;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .btn-secondary {
        margin-right: 1rem;
        color: #212529;
      }
    }
  }
}
.profile-modal-header {
  background-color: var(--secondary);
  span {
    color: #393939;
    font-weight: 600;
    padding: 1rem 0.75rem;
  }
}
.profile-modal-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.auto-save {
  font-size: 12px;
  color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-right: 10px;
}
.dropdown-menu-right {
  position: absolute !important;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.12));
  padding: 0 !important;
  margin: 0 !important;
  border-radius: 0.5rem !important;
  min-width: 12rem !important;
  font-size: 14px !important;
  button:hover {
    background-color: var(--secondary);
    color: #1a335d;
  }
  button:focus {
    outline: none !important;
  }
  .dropdown-item {
    font-weight: 500;
    padding: 0.75rem 1rem !important;

    &:first-child {
      border-top-right-radius: 0.5rem;
      border-top-left-radius: 0.5rem;
    }
    &:last-child {
      border-bottom-left-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }
  }
}
@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
.left-side-bar {
  position: absolute;
  left: 0;
  top: 0;
  animation: slideIn 0.5s ease;
  animation-fill-mode: forwards;
  transform: translateX(-100%);
  z-index: 100;
  padding: 12px 16px;
  padding-left: 0;
  padding-top: 0;
  .sidebar-header {
    width: 30%;
    background-color: #fff;
    box-shadow: -1px -1px 5px 0px rgba(0, 0, 0, 0.25);
  }
}
.left-sidebar-animation {
  transform: translateX(0);
}
.arrowBack-img {
  margin: 1rem;
  cursor: pointer;
}
.vertical-line {
  border-top: 1px solid #d8d8d8;
}

.profile-modal-content {
  .modal-content {
    .modal-body {
      padding-left: 32px;
      padding-right: 32px;
      .profile-modal-body {
        .profile-photo {
          border-radius: 50%;
          width: 120px;
        }
        .form-group {
          .select-label {
            font-size: 14px;
            color: #656565;
          }
          .custom-file {
            .custom-file-label {
              font-size: 12px;
            }
          }
          .description {
            font-size: 13px;
          }
        }
      }
    }
    .modal-footer {
      button {
        min-width: 98px;
        border-radius: 6px;
      }
      .btn-secondary {
        margin-right: 1rem;
      }
    }
  }
}
.profile-modal-header {
  background-color: var(--secondary,$secondary);
  span {
    color: #393939;
    font-weight: 600;
    padding: 1rem 0.75rem;
  }
}
