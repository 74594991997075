// Override default variables before the import
$primary: #1EA0D7;
$secondary: #E8F5FB;
$tertiary: #f2fcff;
$dark: #0b223d;
$white: #ffffff;
$content-bg: #FCFCFC;
$gray: #D8D8D8;
$gray-dark: #dddcdc;
$green: #65be02;
$body-bg: $white;
$footer-bg: #f2f1f1;
$input-field-bg: #f2f1f1;
$body-color: #3e3e3e;
$pink: #ff0000;
$blueBgColor: rgba(0, 19, 41, 0.78);
$input-field-bottom-border-color: $gray-dark;
$formInputRadius: 5px 5px 0 0;
$borderColor: #D8D8D8;
$blue: #1A335D;
$dark-light: #1F1F1F;
$brown-light: #7D7D7D;
$default-loader-primary: #979797;
:root {
  --primary: #{$primary};
  --secondary:#{$secondary};
  --dark: #{$dark};
  --gray: #{$gray};
  --gray-dark: #{$gray-dark};
  --green: #{$green};
  --body-bg: #{$body-bg};
  --footer-bg: #{$footer-bg};
  --input-field-bg: #{$input-field-bg};
  --body-color: #{$body-color};
  --pink: #{$pink};
  --white: #{$white};
  --input-field-bottom-border-color: var(--gray-dark, #{$gray-dark});
  --blueBgColor: #{$blueBgColor};
  --defaultLoaderPrimary: #{$default-loader-primary};
}
