.widget-modal {
    max-width: 792px !important;

    .modal-header {
        .close {
            display: none;
        }
    }

    .modal-body {
        .widget-modal-container {
            display: flex;
            flex-wrap: wrap;

            .note {
                color: #393939;
                font-size: 14px;
                padding-left: 14px;
                padding-bottom: 20px;
                font-weight: 600;
            }


            .form-group {
                .loader-container {
                    position: absolute;
                    transform: translate(-50%, -40%);
                }
                
                .loader {
                    border: 2px solid #f3f3f3;
                    border-radius: 50%;
                    border-top: 4px solid #3498db;
                    width: 20px;
                    height: 20px;
                    animation: spin 1s linear infinite;
                    display: inline-flex;
                }
                
                @keyframes spin {
                    0% { transform: rotate(0deg); }
                    100% { transform: rotate(360deg); }
                }
                .sendotp-loader{
                    width: 90px;
                }
                .input-mobile-container{
                    gap: 12px;
                    .disabled{
                        background-color: var(--primary) !important;
                    }
                    .react-tel-input{
                        .flag-dropdown {
                            height: 35px;
                        }
                    }
                    .sendotp-btn{
                        white-space: nowrap;
                    }
                }
                .input-mobile-notverified {
                    .react-tel-input {
                        .form-control {
                            color: #495057;
                            background-color: #ffffff;
                            box-shadow: 0 0 0 0.5px var(--primary);
                            outline: 0;
                        }
                    }
                }
                &:nth-child(odd) {
                    padding-left: 7px;

                    @media (max-width: 767px) {
                        padding-left: 15px !important;
                        padding-right: 10px;
                    }
                }

                &:nth-child(even) {
                    padding-right: 7px;
                }

                .custom-checkbox {
                    .custom-control-label {
                        &::before {
                            top: 0.32rem !important;
                        }

                        span {
                            position: relative;
                            top: 0.25rem;
                        }
                    }
                }

                .react-tel-input {
                    .form-control {
                        width: 100% !important;
                    }
                }

                label {
                    font-size: .8125rem;
                    color: #393939;
                    margin-bottom: .375rem !important;
                }

                .form-control {
                    font-size: .8125rem;
                    color: #656565;

                    &:disabled {
                        background-color: #f9f9fa !important;
                    }
                }

                .react-tel-input {
                    .flag-dropdown {
                        margin-left: 0px !important;
                        background-color: #f5f5f5 !important;
                        border: 1px solid #cacaca !important;
                    }
                }

                .disabled {
                    background-color: #f9f9fa !important;
                    cursor: not-allowed !important;
                    pointer-events: auto;

                    & [class*="control"] {
                        background-color: #f9f9fa !important;
                    }

                    .css-107lb6w-singleValue {
                        font-size: .8125rem;
                    }

                    .css-1hwfws3 {
                        background-color: #f9f9fa;
                        cursor: not-allowed !important;
                    }

                    .css-1hb7zxy-IndicatorsContainer {
                        background-color: #f9f9fa;
                        cursor: not-allowed !important;
                    }
                }

                .custom-react-select {


                    & [class*="control"] {
                        background-color: hsl(0, 0%, 100%);
                        border: 1px solid hsl(0, 0%, 80%) !important;
                    }

                    .css-1wa3eu0-placeholder {
                        font-size: .8125rem;
                    }

                    .css-1uccc91-singleValue {
                        font-size: .8125rem;
                    }

                    .css-2613-menu {
                        font-size: .8125rem;
                    }
                }

                .input-validation-error-messsage {
                    display: block !important;
                    font-size: 12px;
                    margin-bottom: -8px;
                    position: relative !important;
                }
            }

            .dropwon-error {
                .custom-react-select {
                    & [class*="control"] {
                        border-color: #dc3545 !important;
                    }
                }
            }

            .text-capitalize {
                input {
                    text-transform: capitalize !important;
                }
            }

            .text-uppercase {
                input {
                    text-transform: uppercase !important;
                }
            }

            .text-lowercase {
                input {
                    text-transform: lowercase !important;
                }
            }
        }
    }

    .modal-footer {
        padding-right: 24px;
        height: 64px;
    }
}