/* footer.scss */
@import "../../../veriables.scss";

.footer {
  /*position: fixed;*/
  /*left: 0;*/
  background-color: var(--footer-bg,$footer-bg);
  color: white;
  margin: 1rem;
  /*position: absolute;
  bottom: 0;*/
  width: 100%;
  height: 3rem;
  @media(max-width: 747px){
    text-align: center;
  }
  .terms {
    float: left;
    padding-top: 0.3rem;
    padding-left: 1rem;
    .btn-link {
      padding-right: 0.1px;
      padding-left: 0;
      padding-top: 0.1rem;
    }
  }
  .ee-link {
    float: right;
    padding-right: 1rem;
    .ee-logo-label {
      font-size: 0.8rem;
      margin-right: 0.5rem;
      position: relative;
      top: 0.3rem;
    }
    .ee-logo {
      height: 2rem;
    }
  }
}
@media (max-width: 767px) {
  .footer {
    order: 2;
    height: auto;
    padding-bottom: 1rem !important;
    flex-direction: column;
    display: flex;
    font-size: 0.8rem;
    .terms {
      padding-left: 0;
      a {
        font-size: 0.8rem;
      }
    }
    .ee-link {
      float: initial;
      padding-bottom: 1rem;
    }
  }
}
