@import "veriables.scss";

// @import url("https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i&display=swap");
$theme-colors: (
  primary: $primary,
  dark: $dark,
  green: $green
) !default;

$font-family-sans-serif: "Open Sans", sans-serif;
// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";

$enable-responsive-font-sizes: true;
